

import styles from "./BookingSectionTitle.module.scss";

type MyProps ={
  title:string,
  created?:string
}

const BookingSectionTitle = ({title, created}:MyProps ) => {
  
  return (
    <>
      <div className={styles.title}>
        {title}
        {created && 
        <span className={styles.created}>
          дата создания &ensp; {created}
        </span> }
      </div>
      <div className={styles.divider}></div>
    </>
    )
}

export default BookingSectionTitle ;
