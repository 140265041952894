import React from "react";

import useFeedValidationSchema from "./feedingValidationSchema";
import { Form, Formik } from "formik";

import { ButtonType, FeedingSettingsType, KidsFeegingType } from "../../../../utils/@globalTypes";
import AdultFeedingItem from "./AdultFeedingItem";

import styles from "./FeedingSettings.module.scss";
import Checkbox from "../../../../components/Checkbox";
import Tooltip from "../../../../components/Tooltip";
import { FEEDING_KIDS_REQ } from "../../../../utils/constants";
import KidsFeedingBlock from "./KidsFeedingBlock";
import Button from "../../../../components/Button";
import KidsFreeBlock from "./KidsFreeBlock";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { DeleteIcon } from "../../../../assets/icons";
import ScrollToError from "../../../../components/Booking/ScrollToError";

const FeedingSettings = () => {
  const validationSchema= useFeedValidationSchema();
  const initialValues:FeedingSettingsType = {
    has_breakfast:false,
    has_lunch:false,
    has_dinner:false,
    breakfast:"",
    lunch:"",
    dinner:"",
    has_kidsPrice:false,
    ageKidsFree:"",
    kids:[
      // {
      //   startAge: "",
      //   endAge: "",
      //   kidsBreakfast: "",
      //   kidsLunch: "",
      //   kidsDinner: ""   
      // },      
    ]
  }
  const newKidsBlock:KidsFeegingType = {
    startAge: "",
    endAge: "",
    kidsBreakfast: "",
    kidsLunch: "",
    kidsDinner: ""  
  }
  const {width}= useWindowDimensions();
  
  return( 
     <Formik 
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur = {false}          
        validateOnChange ={false}   
        validateOnMount ={false} 
        onSubmit={(values)=>{console.log(values)}}        
      >
       {(formik) => (
        <Form  >
         <div className={styles.label}>
           Страница в разработке
         </div>
        {/* <div className={styles.container}>
          <h4 className={styles.title}>
            Настройки стоимости питания
          </h4>
          <div className={styles.note}>
            При отсутствии питания перейдите к заполнению других вкладок
          </div>
          <div className={styles.note}>
            При наличии питания выберите необходимые приемы пищи
          </div>
          <div className={styles.adultContainer}>
            <AdultFeedingItem
              title = "Завтрак"
              field = "breakfast"                           
            />
            <AdultFeedingItem
              title = "Обед"
              field = "lunch"                      
            />
            <AdultFeedingItem
              title = "Ужин"
              field = "dinner"                           
            />
          </div>
          <div className={styles.itemLine}>
            <span>
              <Checkbox              
                checked={formik.values.has_kidsPrice}
                label="Отдельная стоимость для детей"
                name={"has_kidsPrice"}
                onClick={() => {
                  formik.setFieldValue("has_kidsPrice", !formik.values.has_kidsPrice)}}       
              />
            </span>
            {formik.values.has_kidsPrice&&
              <Tooltip
              requirementsText={FEEDING_KIDS_REQ}
            />} 
            </div> 
            {formik.values.has_kidsPrice&&
              <div className={styles.kidsContainer}>
                <KidsFreeBlock/>          
                    
                {formik.values.kids.length > 0 &&
                  formik.values.kids.map((item, index) => {
                    
                    return(
                      <div key={index}>
                        <KidsFeedingBlock
                          item = {item}
                          index = {index}
                          error = {formik.errors?.kids&&formik.errors?.kids[index]}
                          
                        />
                      </div>)
                    })}
                <div className={styles.btnWrap}>  
                  <div className={styles.btnAdd}>
                    <Button
                      title={width>600?"Добавить возрастной диапазон":"+"}
                      type={ButtonType.PRIMARY_SMALL}
                      onClick={()=>{                      
                        formik.values.kids.push(newKidsBlock)
                        formik.setFieldValue("kids",formik.values.kids )
                      }}
                    />
                  </div> 
                  {formik.values.kids.length>0 &&
                  <div className={styles.btnRemove}>
                    <Button
                      title={width>600?"Удалить возрастной диапазон":<DeleteIcon />}
                      type={ButtonType.SECONDARY_SMALL}
                      onClick={()=>{                      
                        formik.values.kids.pop()
                        formik.setFieldValue("kids",formik.values.kids )
                      }}
                    />
                  </div>} 
                </div>  
              </div>             
            }
            <div className={styles.btnFormWrap}>     
              <div className={styles.btnSave}>
                <Button 
                  title="Сохранить" 
                  disabled={!formik.dirty}
                  type={ButtonType.PRIMARY_SMALL} 
                  actionType="submit"                                      
                />  
              </div> 
              <div className={styles.btnCancel}>
                <Button 
                  title="Отмена" 
                  type={ButtonType.SECONDARY_SMALL} 
                  actionType="reset" 
                  onClick={formik.resetForm}                                     
                />  
              </div> 
            </div>   
        </div> */}
        <ScrollToError />  
      </Form>
    )} 
    </Formik>
  );
};

export default FeedingSettings;