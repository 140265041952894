import React, { FC, ReactNode, useEffect } from "react";
import moment from "moment";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { TariffsSelectors } from "../../redux/reducers/tariffsSlice";
import { tariffs } from "../../utils/tarifDatas";

import styles from "./PagesContainer.module.scss";
import { setModalWindowData } from "../../redux/reducers/pageSlice";
import { ModalWindowTypes } from "../../utils/@globalTypes";
import { TARIF, TARIF_ORDER, WARNING } from "../../utils/constants";
import { TarifTypes } from "../../redux/types/@types";

type PagesContainerProps = {
  children: ReactNode;
};

const PagesContainer: FC<PagesContainerProps> = ({ children }) => {
  const dispatch = useDispatch();  

  const currentTariff = useSelector(TariffsSelectors.getCurrentTarif)
  const tariffName = tariffs.find(item=> item.code===currentTariff)?.label  
  const tariffExp = useSelector(TariffsSelectors.getTariffExpDate)
  const expTarifCode = localStorage.getItem(TARIF)
  
  useEffect(() => {
    if(tariffExp){
      const today = moment().startOf("day")
      const exp = moment(tariffExp).subtract(3,"hours").startOf("day")    //снимаем 3 часа, добавленные автоматом на часовой пояс
      const diff = exp.diff(today, "days")      
      const todayFormat = moment(today).format("DD MMMM YYYY")
      const memory = localStorage.getItem(WARNING)
      
      if(diff>=8){
        localStorage.removeItem(TARIF_ORDER)
        localStorage.removeItem(WARNING)
      }
      if(diff<8 && diff>0 && (todayFormat!==memory) && currentTariff!=="FREE"){        
        localStorage.setItem(WARNING, todayFormat)
        currentTariff&&localStorage.setItem(TARIF, currentTariff.toString())
        
        dispatch(setModalWindowData({
          type: ModalWindowTypes.TARIFF_WARNING, 
          data:{
            tariffName:tariffName,
            dataExp:tariffExp,
            daysRest:diff
          }}))   
      }
      if(diff===0){        
        localStorage.removeItem(WARNING)
        currentTariff&&localStorage.setItem(TARIF, currentTariff.toString())
        
        if(!localStorage.getItem(TARIF_ORDER)&& currentTariff!=="FREE"){
          dispatch(setModalWindowData({
          type: ModalWindowTypes.TARIFF_WARNING, 
          data:{
            tariffName:tariffName,
            dataExp:tariffExp,
            daysRest:diff
        }}))}   
      }
    }
  }, [tariffExp]);

  useEffect(() => {
    if(expTarifCode&& (currentTariff===TarifTypes.FREE) && (expTarifCode!==currentTariff)){
      
      dispatch(setModalWindowData({
        type: ModalWindowTypes.TARIFF_WARNING, 
        data:{
          tariffName:tariffName,
          dataExp:tariffExp,
          switchFree:true
        }})) 
        currentTariff&&localStorage.removeItem(TARIF)
    } else {
      localStorage.removeItem(TARIF)
    }
  }, [currentTariff, expTarifCode]);
  
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <Sidebar />
      </div>
      <div className={styles.rightWrapper}>
        <Header />
        <div className={styles.contentWrapper}>{children}</div>
      </div>
    </div>
  );
};

export default PagesContainer;
