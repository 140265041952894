import React from "react";
import { BookingFormType, FormBookingType} from "../../../redux/types/bookingTypes";
import { InputType } from "../../../utils/@globalTypes";
import Input from "../../Input";
import BookingSectionTitle from "../BookingSectionTitle";
import { COMMENT_REQ } from "../../../utils/constants";

import styles from './BookingCommentBlock.module.scss';
import { FormikProps } from "formik";

type CommentblockProps ={
  windowType?:BookingFormType,
  formik:FormikProps<FormBookingType>,  
} 

const BookingCommentBlock = ({
  windowType, 
  formik  
}:CommentblockProps) => { 
  
  const commentView = formik.values.client_comment.length>0?formik.values.client_comment: "Дополнительных комментариев нет";
  const {handleChange, values, errors, setFieldTouched} = formik;
  return (
    <div className={styles.wrap}>
      <BookingSectionTitle title="Комментарий клиента"/>
      <div className={styles.container}>
      {windowType===BookingFormType.view&&
            <div className={styles.commentView}>
              {commentView}
            </div>
          }
      {windowType!==BookingFormType.view&&
        <Input
          title="Комментарий клиента"
          name="client_comment"
          id="client_comment"
          type={InputType.TEXTAREA}
          value={values.client_comment}
          placeholder="Введите текст"
          onChange={handleChange("client_comment")}
          isBooking={true}
          className={"comment"}
          onBlur={() => setFieldTouched("client_comment")}
          errText={errors.client_comment}
          requirementsText={COMMENT_REQ}
          maxLength={550}          
        />}
      </div>
    </div>
  )
}

export default BookingCommentBlock ;