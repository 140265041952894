import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

import {   
  BookingType,  
  UpdateBookingType,  
  MainObjectShort,  
  UpdateBookingPayloadData
} from "../types/bookingTypes";

type BookingState = {
  bookingList: BookingType[];
  chessObjectsList: MainObjectShort[] | null;
  isTelephoneValid:boolean;
  chessModalWindow:{
    isOpen:boolean,
    booking:BookingType| null,
    bookingClassName:string 
  }
};

const initialState: BookingState = {
  bookingList: [],
  chessObjectsList: null,
  isTelephoneValid:false,
  chessModalWindow:{
    isOpen:false,
    booking:null,
    bookingClassName: ""
  }
};

const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    getBookingList(_, __) {},
    setBookingList(state, action: PayloadAction<BookingType[]>) {
      state.bookingList = action.payload;
    },
    getChessObjects(_, __) {},
    setChessObjectsList(state, action: PayloadAction<MainObjectShort[] | null>) {
      state.chessObjectsList = action.payload;
    },
    updateCurrentBooking(_, __: PayloadAction<UpdateBookingPayloadData>) {},
    updateViewedStatus(_, __: PayloadAction<number>) {},
    removeBooking(_, __: PayloadAction<number>) {},
    removeBookingFromList(state, action: PayloadAction<number>) {
      state.bookingList = state.bookingList.filter((note) => note.id !== action.payload);
    },
    createBooking(_, __: PayloadAction<UpdateBookingType>) {},
    setIsTelephoneValid(state, action: PayloadAction<boolean>) {
      state.isTelephoneValid= action.payload
    },
    closeChessModalWindow(state){
        state.chessModalWindow= {
          isOpen:false,
          booking:null,
          bookingClassName:""
        }
    },
    setChessModalWindow(state, action: PayloadAction<{booking:BookingType, bookingClassName: string}>){
      state.chessModalWindow= {
        isOpen:true,
        booking:action.payload.booking,
        bookingClassName:action.payload.bookingClassName
      }
    }
}});

export const {
  getBookingList,
  setBookingList,
  getChessObjects,
  setChessObjectsList,
  updateCurrentBooking,
  removeBooking,
  removeBookingFromList,
  createBooking,
  setIsTelephoneValid,
  closeChessModalWindow,
  setChessModalWindow,
  updateViewedStatus
} = BookingSlice.actions;

export default BookingSlice.reducer;

export const bookingName = BookingSlice.name;

export const BookingSelectors = {
  getBookingList: (state: RootState) => state.booking.bookingList,
  getChessObjects: (state: RootState) => state.booking.chessObjectsList,
  getIsTelephoneValid: (state: RootState) => state.booking.isTelephoneValid,
  getChessModalWindow: (state: RootState) => state.booking.chessModalWindow,
};
