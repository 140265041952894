import React, { FC, useEffect, useState } from "react";
import { BookingType, BookingFormType } from "../../redux/types/bookingTypes";
import styles from "./CheckerboardBanner.module.scss";
import  classNames  from 'classnames';
import moment from "moment";
import { TooltipIcon } from "../../assets/icons";
import BannerInner from "./BannerInner";
import { useDispatch } from "react-redux";
import { setModalWindowData } from "../../redux/reducers/pageSlice";
import { ModalWindowTypes } from "../../utils/@globalTypes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { setChessModalWindow } from "../../redux/reducers/bookingSlice";

type BannerProps = {
  booking:BookingType,
  cellWidth:number,
  type:  "start" | "ending",
  currentDay:moment.Moment 
}

const CheckerboardBanner:FC<BannerProps> = ({
  booking,
  cellWidth,
  type,
  currentDay  
}) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [diffLimit, setdiffLimit] = useState<number>(4);
  
  useEffect(()=>{          //  изменяем ширину ячейки таблицы в зависимости от ширины окна
    if(width<=600 && diffLimit!==6){
      setdiffLimit(5)
    } else if(width>600 && diffLimit!==4){
      setdiffLimit(4)
    }
  }, [width])
  const handlerViewBooking=()=>{  
    dispatch(setModalWindowData({type: ModalWindowTypes.SHOW_RESERVATION, data:{booking:booking, type:BookingFormType.view}}))        
  }
  const openModalWindow = () =>{
    dispatch(setChessModalWindow({booking:booking, bookingClassName:bookingClassname}))
  } 
  const today = new Date();
  
  const bookingClassname = moment(booking.check_out_date).startOf('day').unix()<moment(today).startOf('day').unix()? "last"
  : booking.status==="awt"? "await"
  : +booking.total===+booking.paid? "full_paid" 
  : +booking.paid>0? "part_paid"
  : "not_paid";
  
  const diff = type==="start"? moment(booking.check_out_date).diff(moment(booking.check_in_date),'days')
  : moment(booking.check_out_date).diff(currentDay,'days')

  const bannerWidth= type==="start"? (cellWidth*diff-2) : (cellWidth*diff)+ (cellWidth/2)-2
  const leftPosition= type==="start"? (cellWidth/2+1) : 1  
  
    if(diff<diffLimit){
    return (
      // <>
      <div className={classNames(styles.container, styles[bookingClassname], styles.tooltip)}
        style={{width:(cellWidth*diff-2), left:(cellWidth/2+1)}}
        onClick={ width>932 ? handlerViewBooking : openModalWindow}
      >
        <div className={styles.tt_name}>
          {booking.last_name} {booking.first_name}
        </div>
        <div className={styles.tt_icon}>
          <TooltipIcon />
        </div>
        <div className={styles.tt_full}>
          <BannerInner 
            booking={booking} 
            bookingClassname={bookingClassname} 
            bannerWidth={cellWidth*4} 
            left={0}
            isTooltip={true}/>
        </div>
      </div>
      // </>
    )
  }
  return (    
    <BannerInner 
      booking={booking} 
      bookingClassname={bookingClassname} 
      bannerWidth={bannerWidth} 
      left={leftPosition}
      isTooltip={false}
    />
  )
}


export default CheckerboardBanner;