import { Value } from 'sass';
import * as  Yup  from  'yup' ;
import { REQUIRED_FIELD_ERROR2, TOTAL_INFO_ERROR } from '../../../../utils/constants';

const useFeedValidationSchema = () => {

  const validationSchema=Yup.object().shape ({  
    has_breakfast:Yup
    .boolean(),            
    has_lunch:Yup
    .boolean(),         
    has_dinner:Yup
    .boolean(),      
    breakfast:Yup
    .string()
    .when("has_breakfast", {
      is:(has_breakfast: boolean) => has_breakfast === true,
      then: (validationSchema) => validationSchema.max(8,TOTAL_INFO_ERROR).required(REQUIRED_FIELD_ERROR2),
      otherwise: (validationSchema) => validationSchema.nullable().default("")
    }),
    
    lunch:Yup
    .string()
    .default("")
    .when("has_lunch", {
      is:(has_lunch: boolean) => has_lunch === true,
      then: (validationSchema) => validationSchema.max(8,TOTAL_INFO_ERROR).required(REQUIRED_FIELD_ERROR2),
      otherwise: (validationSchema) => validationSchema.nullable().default("")
    }),    
    dinner:Yup
    .string()
    .when("has_dinner", {
      is:(has_dinner: boolean) => has_dinner === true,
      then: (validationSchema) => validationSchema.max(8,TOTAL_INFO_ERROR).required(REQUIRED_FIELD_ERROR2),
      otherwise: (validationSchema) => validationSchema.nullable().default("")
    }),
    
    has_kidsPrice:Yup
    .boolean(),    
    ageKidsFree:Yup
    .string()
    .max(2, TOTAL_INFO_ERROR),

    kids:Yup.array().of(
      Yup.object().shape({
        startAge: Yup.string().required(REQUIRED_FIELD_ERROR2),
        endAge: Yup.string().required(REQUIRED_FIELD_ERROR2)
        .test("is-kidsAge-valid", "Неверное значение", 
          function (endAge) {            
          const { startAge } = this.parent;            
          if(startAge && endAge) {                        
            return Number(endAge)>Number(startAge)
          }          
          return true        
        }), 
        kidsBreakfast: Yup.string()
        
        .test("is-kidsBreakfast-valid", REQUIRED_FIELD_ERROR2, 
          function (kidsBreakfast) {            
          const  has_breakfast  = this.options.context?.has_breakfast            
          if(has_breakfast) {                        
            return has_breakfast && kidsBreakfast
          }          
          return true        
        }),           
        
        kidsLunch: Yup.string()
        .test("is-kidsLunch-valid", REQUIRED_FIELD_ERROR2, 
          function (kidsLunch) {
          const  has_feeding  = this.options.context?.has_lunch; 
          if(has_feeding) {return has_feeding&&kidsLunch}          
          return true         
        }), 

        kidsDinner: Yup.string() 
        .test("is-kidsDinner-valid", REQUIRED_FIELD_ERROR2, 
          function (kidsDinner) {
          const  has_feeding  = this.options.context?.has_dinner; 
          if(has_feeding) {return has_feeding&&kidsDinner}          
          return true         
        }),       
      })
    ),
  })
return validationSchema;
}
export default useFeedValidationSchema