import { AddressType } from "../../utils/@globalTypes";

export type PayloadWithCallback<Data> = {
  data: Data;
  callback?: (value?: any, value2?: any) => void;
  value?: any;
};

export type LimitWithOffsetPayload = {
  limit?: number;
  offset?: number;
};

export type GetDataPayload = {
  value: number;
  data?: { limit?: number; offset?: number };
};

// RESPONSE TYPES

export type ErrorResponseData = {
  email?: string[];
  first_name?: string[];
  last_name?: string[];
  middle_name?: string[];
  password?: string[];
  phone_number?: string[];
  website?: string[];
  position?: string[];
  // mobile_phone?: string[];
  // work_phone?: string[];
  code?: string[];
  object_title?: string[];
  name?: string[];
  object_type?: string[];
  country?: string[];
  permission?: string[];
  photo?: string[];
  booking_photo?: string[];
  region?: string[];
  locality?: string[];
  address?: string[];
  token?: string;
  unp?: string[];
  entity_address?: AddressType;
  post_address?: AddressType;
  start_date?: string[];
  rental_object?: string[];
};

export type ErrorResponseDetailsData = {
  detail: string;
};

export type ResponseData<T> = {
  count: number;
  next: string;
  previous: string;
  results: T;
};

export type UserInfoType = {
  id: number;
  email: string;
  is_active: boolean;
  status: string;
};

export type ResponseOptionsData = {
  id: number;
  title: string;
};

export type PayloadDataWithId<T> = {
  id: number;
  data: T;
};

export type TarifCardType = {
  label: string;
  code: TarifTypes;
  features: string[];
  isPaying?:boolean
};

export type TarifFeaturesType = {
  subTitle:string,
  tariffs:{
    free:boolean,
    start:boolean,
    pro: boolean,
    pro_plus: boolean
  }
}

export type TarifAbilityType = {
  title:string,
  features: TarifFeaturesType[]
}

export enum TarifTypes { 
  START = "START",
  PRO = "PRO",
  PRO_PLUS = "PRO_PLUS",
  FREE = "FREE"
}

export type TariffsInfoType = {
  id:number,
  value:string,
  half_year_cost: string,
  year_cost: string,
  cost: string,
  created_at:string,
  updated_at:string
}

export type TariffsValue = {
  monthlyPrice:string,
  halfYearPrice:string,
  yearPrice:string
}

export type TariffOrderType = {
  value:TarifTypes,
  duration:number  
}